import React, {useState} from 'react';
import {Button, Dropdown, Icon, Modal} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Mutation, Query} from 'react-apollo';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-semantic-toasts';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import {USERS_ADMIN, hasRole} from '../../utils/roles';
import {toastSucces} from '../common/Toast';

export const ARCHIVE_USER = gql`
  mutation archiveUser($id: ID!, $archive: Boolean!) {
    archiveUser(_id: $id, archive: $archive)
  }
`;

const GET_ALL_USERS = gql`
  query {
    users {
      _id
      archive
      firstName
      lastName
    }
    currentUser {
      _id
      roles
    }
  }
`;

const GET_USER_SKILLS = gql`
  query($userId: ID!) {
    user(_id: $userId) {
      latestSkillEntries {
        _id
        knowledge
        liking
        skill {
          name
          _id
        }
      }
    }
  }
`;

const MenuUsers = ({
  history,
  match: {
    params: {userId}
  }
}) => {
  const [openModal, setOpenModal] = useState(false);

  const getSkills = data => {
    const {latestSkillEntries} = data.user;
    const excludedSkills = [
      'react',
      'typescript',
      'javascript',
      'css',
      'html',
      'manual testing',
      'zjedzenie całej 1kg golonki na raz',
      'parzenie kawy',
      'contact with client'
    ];

    const skills = latestSkillEntries
      .filter(entry => entry.knowledge >= 3 && !excludedSkills.includes(entry.skill.name.toLowerCase()))
      .sort((a, b) => b.knowledge - a.knowledge)
      .map(entry => `${entry.skill.name} (${entry.knowledge})`);

    navigator.clipboard.writeText(skills.join(', '));
    toastSucces('Skills copied to clipboard');
  };

  return (
    <Query query={GET_ALL_USERS}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {currentUser, users} = data || {};

        const options = users
          .filter(({archive}) => !archive)
          .map((user, i) => {
            return {
              key: i,
              text: `${user.firstName} ${user.lastName}`,
              value: user._id
            };
          })
          .sort((a, b) => a.text.localeCompare(b.text));

        return (
          <Mutation mutation={ARCHIVE_USER} refetchQueries={() => [{query: GET_ALL_USERS}]}>
            {archiveUser => {
              return (
                <span className="header-select">
                  <Dropdown
                    button
                    className="icon"
                    floating
                    labeled
                    icon="user"
                    search
                    options={options}
                    text="Change user"
                    onChange={(e, {value}) => {
                      history.push(`/user/${value}`);
                    }}
                  />

                  <Query query={GET_USER_SKILLS} variables={{userId}}>
                    {({data = {}, loading, error}) => {
                      return (
                        <Button icon labelPosition="left" onClick={() => getSkills(data)}>
                          Get 3+ skills
                          <Icon name="download" />
                        </Button>
                      );
                    }}
                  </Query>
                  {hasRole(currentUser, USERS_ADMIN) && (
                    <Modal
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                      onOpen={() => setOpenModal(true)}
                      trigger={
                        <Button icon labelPosition="left">
                          Archive user
                          <Icon name="archive" />
                        </Button>
                      }
                    >
                      <Modal.Content>
                        <p>Are you sure, that you want to move this user profile to the Archive?</p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button color="red" onClick={() => setOpenModal(false)}>
                          <Icon name="remove" /> No
                        </Button>
                        <Button
                          color="green"
                          onClick={() => {
                            setOpenModal(false);
                            const archived = archiveUser({variables: {id: userId, archive: true}});
                            if (archived) {
                              toast({
                                description: `User has been archived`,
                                time: 3000
                              });
                              history.push('/users');
                            } else {
                              toast({
                                description: `Something went wrong`,
                                time: 3000
                              });
                            }
                          }}
                        >
                          <Icon name="checkmark" /> Yes
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  )}
                </span>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};
export default withRouter(MenuUsers);
