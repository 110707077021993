import React, {Fragment} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import ErrorPage from '../common/ErrorPage';
import NotFound from '../common/NotFound';
import {Toast} from '../common/Toast';
import Loader from '../common/Loader';
import Header from '../Header';
import Footer from '../Footer';
import {ROUTES_LOGGED_OUT, ROUTES_LOGGED_IN, ROUTE_LOGIN, ROUTES_ADMIN_LOGGED_IN} from '../../const';
import {hasRole, USERS_ADMIN} from '../../utils/roles';

const GET_USER = gql`
  query user {
    currentUser {
      _id
      defaultView
      firstName
      lastName
      roles
    }
  }
`;

const Routes = ({history}) => {
  return (
    <Query query={GET_USER}>
      {({data = {}, loading, error, refetch}) => {
        const {currentUser} = data;
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;
        let defaultView = currentUser ? (currentUser.defaultView ? currentUser.defaultView : '/skills') : '/skills';
        const {
          location: {search}
        } = history;
        if (search) {
          const params = new URLSearchParams(search);
          defaultView = params.get('redirect');
        }

        const routesLoggedIn = currentUser
          ? hasRole(currentUser, USERS_ADMIN)
            ? ROUTES_ADMIN_LOGGED_IN
            : ROUTES_LOGGED_IN
          : [];

        return (
          <Fragment>
            <Toast />
            <Header user={currentUser} history={history} refetch={refetch} />
            <div className="routes-container">
              <Switch>
                {currentUser ? (
                  <Fragment>
                    <Switch>
                      <Redirect from={'/'} exact strict to={defaultView} />
                      {routesLoggedIn.map(({pathName, Component}) => (
                        <Route key={pathName} path={pathName} exact component={Component} />
                      ))}
                      <Route component={NotFound} />
                    </Switch>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Switch>
                      {ROUTES_LOGGED_OUT.map(({pathName, Component}) => (
                        <Route
                          key={pathName}
                          path={pathName}
                          exact
                          render={({history}) => <Component refetch={refetch} history={history} user={currentUser} />}
                        />
                      ))}
                      <Redirect from={'/*'} strict exact to={ROUTE_LOGIN} />
                    </Switch>
                  </Fragment>
                )}
              </Switch>
            </div>
            <Footer user={currentUser} history={history} refetch={refetch} />
          </Fragment>
        );
      }}
    </Query>
  );
};

export default withRouter(Routes);
