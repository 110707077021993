import React, {Fragment} from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import {ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend} from 'recharts';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import {dataLikingOffset, domainLiking, domainKnowledge} from './chartSettings';

export const GET_SKILL_SUMMARY = gql`
  query {
    skillsTrends {
      name
      count
      knowledge
      liking
    }
  }
`;

const TrendsChart = () => {
  return (
    <Query query={GET_SKILL_SUMMARY}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {skillsTrends = []} = data;
        const firstSkillsTrend = skillsTrends[0]
        const defaultCount = (firstSkillsTrend && firstSkillsTrend.count) || 0;
        const votesMax = skillsTrends.reduce((prev, {count}) => Math.max(prev, count), defaultCount);
        const votesMin = skillsTrends.reduce((prev, {count}) => Math.min(prev, count), defaultCount);
        const bubbles = skillsTrends.map(({knowledge, liking, count, name}) => {
          return {
            knowledge: knowledge.toFixed(2),
            liking: (liking - dataLikingOffset).toFixed(2),
            votes: count,
            label: name
          };
        });

        return (
          <Fragment>
            <h1>Skills Trends</h1>
            {!skillsTrends.length && (
              <p>You can not see Skill Trends chart if no one added their skills Knowledge & Liking</p>
            )}
            {skillsTrends.length && (
              <ScatterChart width={1000} height={500} margin={{top: 20, right: 20, bottom: 10, left: 10}}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="knowledge"
                  domain={domainKnowledge}
                  type="number"
                  label={{value: 'Knowledge', position: 'insideBottomRight'}}
                />
                <YAxis
                  dataKey="liking"
                  domain={domainLiking}
                  type="number"
                  label={{value: 'Liking', position: 'insideTopLeft'}}
                />
                <ZAxis dataKey="votes" range={[votesMin * 100, votesMax * 100]} name="votes" />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Scatter name="Votes on Skill" data={bubbles} fill="#8884d8" />
              </ScatterChart>
            )}
          </Fragment>
        );
      }}
    </Query>
  );
};

const CustomTooltip = ({active, payload}) => {
  if (active) {
    return (
      <div className="chart-summary-tooltip">
        <p className="tooltip-label">{`Name : ${payload[0].payload.label}`}</p>
        <p className="tooltip-label">{`Votes : ${payload[2].value}`}</p>
        <p className="tooltip-label">{`Knowledge: ${payload[0].value}`}</p>
        <p className="tooltip-label">{`Liking : ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

export default TrendsChart;
