import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

const GET_ALL_USERS = gql`
  query {
    users {
      firstName
      lastName
      _id
      archive
    }
  }
`;

const MenuUsers = ({onSetUserId}) => {
  return (
    <Query query={GET_ALL_USERS}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {users} = data || {};

        const options = users
          .filter(user => !user.archive)
          .map((user, i) => {
            return {
              key: i,
              text: `${user.firstName} ${user.lastName}`,
              value: user._id
            };
          })
          .sort((a, b) => a.text.localeCompare(b.text));

        return (
          <span className="header-select">
            <Dropdown
              button
              className="icon"
              floating
              labeled
              icon="user"
              search
              options={options}
              text="Change user"
              onChange={(e, {value}) => {
                onSetUserId(value);
              }}
            />
          </span>
        );
      }}
    </Query>
  );
};
export default MenuUsers;
