import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import {USERS_ADMIN, hasRole} from '../../utils/roles';

export const GET_USERS = gql`
  query {
    users {
      _id
      archive
      firstName
      lastName
    }
    currentUser {
      _id
      roles
    }
  }
`;

const headerRows = [{label: 'Name', sortBy: 'label'}];

const CompanyUsersTable = ({location: {pathname}}) => {
  const archive = pathname.slice(1) === 'archive';
  return (
    <Query query={GET_USERS} variables={{archive}}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {users = [], currentUser = {}} = data;

        const filterArchived = user => {
          if (archive) {
            return !!user.archive === archive;
          }
          return hasRole(currentUser, USERS_ADMIN) || !user.archive;
        };

        const rows = users.filter(filterArchived).map(({firstName, lastName, _id, archive: userArchived}) => ({
          label: `${firstName} ${lastName}`,
          endPoint: `/user/${_id}`,
          hasRated: false,
          archive,
          userArchived
        }));

        return (
          <Table
            rows={rows}
            title="Users"
            headerRows={headerRows}
            currentUser={currentUser}
            className="company-users-table"
          />
        );
      }}
    </Query>
  );
};

export default CompanyUsersTable;
