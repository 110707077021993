import React, {useState} from 'react';
import {Button, Confirm} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Mutation, Query} from 'react-apollo';

import MenuSkills from './MenuSkills';
import AddSkill from './AddSkill';
import {ratingLabels, likingLabels} from './getLabel';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import {toastSucces, toastError} from '../common/Toast';
import AddSkillsTable, {GET_ALL_SKILL_ENTRIES} from './AddSkillsTable';
import {GET_SKILLS} from '../CompanySkillsTable';
import {GET_USER} from '../UserTable';
import {GET_SKILL} from '../SkillTable';
import Rating from '../Rating';

const CREATE_SKILL_ENTRY = gql`
  mutation createSkillEntryMutataion($input: CreateSkillEntryInput!) {
    createSkillEntry(input: $input)
  }
`;

export const GET_CURRENT_USER = gql`
  query user {
    currentUser {
      _id
      roles
    }
  }
`;

const SkillEntryForm = () => {
  const [knowledge, setKnowledge] = useState(1);
  const [liking, setLike] = useState(3);
  const [selectedSkill, setSkill] = useState({});
  const [isOpen, setOpen] = useState(false);

  return (
    <Query query={GET_CURRENT_USER}>
      {({data = {}, loading, error}) => {
        const {
          currentUser: {_id}
        } = data;

        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {skillName, selectedId} = selectedSkill;

        return (
          <Mutation
            mutation={CREATE_SKILL_ENTRY}
            refetchQueries={() => [
              {query: GET_SKILLS},
              {query: GET_ALL_SKILL_ENTRIES},
              {query: GET_USER, variables: {userId: _id}},
              {query: GET_SKILL, variables: {skillId: selectedId}}
            ]}
          >
            {(createSkillEntry, {loading, error}) => {
              const {message} = error || '';
              if (loading) return <Loader />;
              if (error) return <ErrorPage text={message} />;
              return (
                <div className="skill-entry-form">
                  <div className="add-skill-user">
                    <MenuSkills skill={selectedId} onSetSkill={setSkill} />
                    <Rating
                      range={[1, 5]}
                      descriptions={ratingLabels}
                      size="massive"
                      icon="star"
                      onRate={(e, {rating}) => setKnowledge(rating)}
                      rating={knowledge}
                    />
                    <Rating
                      range={[1, 5]}
                      descriptions={likingLabels}
                      size="massive"
                      icon="heart"
                      onRate={(e, {rating}) => setLike(rating)}
                      rating={liking}
                    />
                    <Button onClick={() => setOpen(true)} secondary>
                      Add skill
                    </Button>

                    <Confirm
                      open={isOpen}
                      content={
                        skillName
                          ? `Do you want add ${skillName} with knowledge ${knowledge} and liking ${liking}?`
                          : `Please fill the skill name`
                      }
                      onCancel={() => setOpen(false)}
                      onConfirm={async () => {
                        if (!knowledge || !liking || !selectedId) {
                          setOpen(false);
                          toastError('Please choose technology and set ratings');
                        } else {
                          setOpen(false);
                          setSkill({});
                          setKnowledge(1);
                          setLike(3);
                          const {
                            data: {createSkillEntry: isCreateSkillEntry}
                          } = await createSkillEntry({
                            variables: {
                              input: {
                                knowledge,
                                liking,
                                skillId: selectedId,
                                userId: _id
                              }
                            }
                          });

                          if (isCreateSkillEntry) {
                            toastSucces('SkillEntry has been added');
                          } else {
                            toastError('SkillEntry has not been added');
                          }
                        }
                      }}
                    />
                  </div>
                  <AddSkillsTable title="My skills" />
                  <AddSkill />
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};

export default SkillEntryForm;
