import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import {withRouter} from 'react-router-dom';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

const GET_ALL_SKILLS = gql`
  query {
    skills {
      name
      _id
    }
  }
`;

const MenuSkills = ({history}) => {
  return (
    <Query query={GET_ALL_SKILLS}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {skills} = data || {};
        const options = skills
          .map((skill, i) => {
            return {
              key: i,
              text: skill.name,
              value: skill._id
            };
          })
          .sort((a, b) => a.text.localeCompare(b.text));

        return (
          <span className="header-select">
            <Dropdown
              button
              className="icon"
              floating
              labeled
              icon="address card"
              search
              options={options}
              text="Select skill"
              onChange={(e, {value}) => {
                history.push(`/skill/${value}`);
              }}
            />
          </span>
        );
      }}
    </Query>
  );
};
export default withRouter(MenuSkills);
